import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ACL_VIEW_ROLES } from '@remento/types/acl';
import { NotFoundError } from '@remento/types/error';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RementoPage } from '@/modules/routing';
import { StoryDialogContainer } from '@/modules/stories/containers/StoryDialog.container';
import { createStoriesManager } from '@/modules/stories/states/stories.manager';
import { VideoManagerProvider } from '@/modules/stories/states/video.manager';
import { hasRole, useCurrentUserAclRoles } from '@/services/api/acl';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { useProjectQuery } from '@/services/api/project';
import { useStoryQuery } from '@/services/api/story';

export interface InternalStoryPageProps {
  storyId: string;
}

export function InternalStoryPage({ storyId }: InternalStoryPageProps) {
  // Services
  const navigate = useNavigate();
  const user = useUser();

  // Queries
  const storyQuery = useStoryQuery(storyId);
  const projectId = storyQuery.data?.projectId ?? null;
  const projectQuery = useProjectQuery(user != null ? projectId : null);

  // Managers
  const storiesManager = useMemo(
    () =>
      projectId != null ? createStoriesManager([storyId], projectId, storyId, navigate, 'story-standalone') : null,
    [storyId, projectId, navigate],
  );

  // If there's a signed in user, try tro access the project from the story
  // If the user have access to it, we should let the user close the standalone viewer and go
  // to the stories page.
  const userProjectRoles = useCurrentUserAclRoles(projectQuery.data?.acl ?? null);
  const canViewProject = hasRole(ACL_VIEW_ROLES, userProjectRoles ?? []);

  if (storiesManager == null) {
    return <PageLoader />;
  }

  return (
    <VideoManagerProvider>
      <StoryDialogContainer storiesManager={storiesManager} showClose={canViewProject} />
    </VideoManagerProvider>
  );
}

export function StoryPage() {
  const params = useParams();
  const storyId = params.storyId;

  if (storyId == null) {
    throw new NotFoundError('story-id-param-not-found');
  }

  return (
    <RementoPage type="default">
      <InternalStoryPage storyId={storyId} />
    </RementoPage>
  );
}
